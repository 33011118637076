
<template>
    <div class="aside-container">
          <div class="logo-content">
              <div class="logo-con" v-if="!isCollapse">
                  <i class="icon zx-logo icon-logo2"  @click="goPortal"></i>
                  <!-- <img style="cursor: pointer;width:78px;height:40px"  @click="goPortal" src="../../assets/img/logo.png"  alt=""> -->
                  <el-divider direction="vertical"></el-divider>
                  <span class="txt">开放平台</span>
              </div>
                <div class="logo-image" v-if="isCollapse">
                  <div class="icon">
                    <img src="../../assets/img/logoFold.png"  alt="">
                  </div>
              </div>
            </div>
            <el-menu :unique-opened="true"
                    :collapse="isCollapse"
                    :default-active="$route.path"
                    :default-openeds="defaultOpen"
                    class="aside-menu"
                    text-color="#fff"
                    :router="true"
                    active-text-color="#fff">

                <template v-for="item in permissionRoutes">
                     <!-- 有子菜单的节点 -->
                    <template v-if="item.children && !item.showChildrenRouter">
                      <el-submenu
                      class="menu-item"
                      :index="item.path"
                      :key="item.path">
                          <template slot="title">
                              <i class="icon ft-fff" :class="item.icon"></i>
                              <span slot="title" class="title">{{item.meta.title}}</span>
                          </template>
                          <!-- 子菜单 -->
                        <template v-for="(child, index) in item.children">
                          <el-menu-item  v-if="!child.isShowMenu" class="menu-item" :key="index" :index="child.path">
                           <i class="icon ft-fff" :class="child.icon"></i>
                           <span class="title"  slot="title">{{child.meta.title}}</span>
                          </el-menu-item>
                        </template>

                      </el-submenu>
                     </template>
                      <!--没有子菜单的节点  -->
                     <template v-else>
                        <el-menu-item :key="item.path"
                                       class="menu-item"
                                      :index="item.path">
                            <template >
                               <i class="icon ft-fff" :class="item.icon"></i>
                              <span slot="title" class="title">{{ item.meta.title }}</span>
                            </template>
                        </el-menu-item>
                    </template>
                 </template>
            </el-menu>
    </div>
</template>

<script>
import { getStorageItem } from '@/utils'
export default {
  name: 'AppMenu',
  data () {
    return {
      permissionRoutes: [],
      defaultOpen: [],
      userName: '',
      authorizationList: getStorageItem('authorizationList'),
      // isCollapse: false
    }
  },
  created () {
    this.userName = getStorageItem('userName')
    this.defaultOpen.push(`/${this.$route.path.split('/')[1]}`)
  },
  computed: {
    isCollapse () {
      return this.$store.state.openplate.isCollapse
    }
  },
  mounted () {
    this.permissionRoutes = this.$router.options.routes[1].children
  },
  methods: {
    goPortal(){
       window.open(
              `${window.toolbox_redirectUrl.portal}`,
              "_self"
        )
    },
    checkChild (arr) { // 检查该菜单是否有子菜单，无则隐藏
      if (Object.prototype.toString.call(arr) !== '[object Array]') {
        return
      }
      let flag = 1
      arr.forEach((item) => {
        if (this.authorizationList.includes(item)) {
          flag *= 0
        }
      })
      return flag === 0
    }
  },
  watch: {
    $route () {
      // 每次路由变化滚动到顶部
      window.scroll(0, 0)
    }
  }
}
</script>

<style scoped lang="scss">
 ::v-deep.el-menu--vertical {
   .el-menu--popup{
    padding:0;
    min-width: 0px;
  }
 }
// 整个左侧导航
.aside-container {
    height: 100%;
    position: relative;
}
.ft-fff{
  color: #fff;
}
// 左侧导航宽度
.aside-menu:not(.el-menu--collapse) {
    width: 200px;
    box-sizing: border-box;
}
// 上方title标题
.logo-content {
    width: 100%;
    height: 66px;
    display: flex;
    align-items: center;
    color: #f0f0f0;
    background: #0d1c43;
    .logo-image {
        height: 100%;
        width: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #0d1c43;
        .icon {
            height: 33px;
            width: 33px;
            // background: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 33px;
            img {
                height: 100%;
                width: 100%;
            }
        }
    }
    .logo-con {
        text-align: left;
        width: 200px;
        height: 58px;
        padding-left: 10px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        background-color: #0d1c43;
        .zx-logo{
          font-size: 37px;
          cursor: pointer;
        }
        img{
          width: 78px;
          height: 40px;
        }
        .txt{
          color: #fff;
          font-size: 18px;
        }
    }
}
// hover后
.el-menu--popup-right-start {
    .i-icon {
        color: #fff !important;
        font-size: 16px;
    }
    span {
        color: #fff !important;
    }
    .el-menu-item {
        height: 40px;
        line-height: 40px;
        background: #002242 !important;
        &:hover {
            color: #fff !important;
            background: #253a6c !important;
        }
    }
}

// 覆盖element样式
.aside-container {
    ::v-deep .el-menu-item:focus,
    .el-menu-item:hover {
        outline: 0;
        background: #253a6c;
    }
}

.aside-menu {
    background: #152857;
    color: #fff;
    border-right: none;
    .i-icon {
        color: #fff;
        font-size: 16px;
    }
     ::v-deep .el-submenu .el-menu-item{
       height: 56px;
       line-height: 56px;
     }
    ::v-deep .el-submenu__title {
        color: #fff;
        font-size: 14px;
        .el-submenu__icon-arrow {
            color: #fff;
            font-size: 16px;
        }
    }
    ::v-deep .el-submenu__title:hover {
        background-color: #253a6c;
    }
   ::v-deep .el-submenu__title:focus,
    .el-submenu__title:hover {
        color: #fff;
        background: #253a6c;
    }
    ::v-deep .el-menu {
        border: none;
        background-color: #002242;
    }
    ::v-deep .el-menu-item {
        color: #fff;
        font-size: 14px;
        background: #152857;
    }
    ::v-deep .el-menu-item .is-active {
        background: #253a6c;
        color: #fff;
    }
    ::v-deep .el-menu-item.is-active:before{
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            transition: all .3s ease;
            width: 3px;
            height: 55px;
            background-color: #a0b4e5;
      }
    ::v-deep .el-menu-item.menu-item.is-active{
        background-color: #253a6c;
    }
    // 二级
    ::v-deep .el-menu--inline {
        .menu-item {
            font-size: 12px;
           // background: #072047;
        }
    }
    .title{
      margin-left: 5px;
    }
}

    .app-menu::-webkit-scrollbar {/*滚动条整体样式*/
      width: 4px;     /*高宽分别对应横竖滚动条的尺寸*/
    }
    .app-menu::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
      border-radius: 5px;
      box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
      background: rgba(0,0,0,0.2);
    }
    .app-menu::-webkit-scrollbar-track {/*滚动条里面轨道*/
      box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
      border-radius: 0;
      background: rgba(0,0,0,0.1);
    }

</style>
