<!--
 * @Author: your name
 * @Date: 2020-04-09 15:43:17
 * @LastEditTime: 2020-05-21 10:18:39
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /frontend-toolbox/src/views/Home.vue
 -->
<template>
  <div class="app-main">
    <el-container>
      <div style="height:100%;background: #152857">
         <app-menu></app-menu>
      </div>
       <el-container>
         <el-header style="font-size: 12px">
             <app-header class="app-header"></app-header>
         </el-header>
           <el-main class="main-style">
              <transition name="fade-transform" mode="out-in">
                <div style="background: #fff;height:100%">
                    <router-view/>
                </div>
                </transition>
           </el-main>
        </el-container>
    </el-container>
  </div>
</template>

<script>
import AppMenu from '@/components/layer/AppMenu'
import AppHeader from '@/components/layer/AppHeader'
export default {
  name: 'Home',
  components: {
    AppMenu,
    AppHeader
  }
}
</script>

<style scoped>
   .app-main{
        display: flex;
        flex-wrap: wrap;
        height: 100%;
        /* min-width: 1280px; */
    }

    .main-style{
        background: #fff;
        /* margin: 20px; */
        padding:20px;
        background: #eef1f7;
    }
    /*fade*/
    .fade-enter-active,
    .fade-leave-active {
    transition: opacity 0.28s;
    }

    .fade-enter,
    .fade-leave-active {
    opacity: 0;
    }

    /*fade-transform*/
    .fade-transform-leave-active,
    .fade-transform-enter-active {
    transition: all .5s;
    }
    .fade-transform-enter {
    opacity: 0;
    transform: translateX(-30px);
    }
    .fade-transform-leave-to {
    opacity: 0;
    transform: translateX(30px);
    }

    .el-header{
      padding: 0;
      background: #fff;
    }
    .eef1f7{
      background:#eef1f7 ;
    }

</style>
